<template>
    <app-primary class="app--main">
        <main-visual />

        <main-section-primary class="main-section--sm">
            <v-row align="center">
                <v-col cols="12" md="5">
                    <tit-tertiary class="font-weight-bold"
                        ><span class="secondary--text"
                            >불광동 루미노816<br class="d-none d-md-block" />
                            청년안심주택</span
                        >은<br class="d-none d-sm-block" />
                        최고의 주거환경을 제공합니다.</tit-tertiary
                    >
                </v-col>
                <v-col cols="12" md="7">
                    <txt-primary class="line-height-15">
                        연신내역 역세권 청년안심주택 루미노816은 지하철 3호선·6호선 및 GTX역 인근에 위치하며 <br class="d-none d-xl-block" />
                        지하 3층 ~ 지상 22층, 총 299세대로 계획된 임대전용 공동주택입니다. <br class="d-none d-xl-block" />
                        루미노816은 다양한 사업영역에서 축적한 노하우와 전문 인력들을 바탕으로 도시의 공간에 새로운 가치를 더하고, <br class="d-none d-xl-block" />
                        청년들의 첫 시작을 도우려고 노력하고 있습니다.
                    </txt-primary>
                </v-col>
            </v-row>
        </main-section-primary>

        <main-section-primary class="main-section--sm main-section--special">
            <v-row justify="space-between" align="center">
                <v-col cols="12" md="5" lg="4" xl="3">
                    <tit-wrap-primary titWrapSize="tit-wrap--sm" subTitSize="tit--xxs" subTitClass="secondary--text" titClass="white--text font-weight-bold">
                        <template #TitAdd>SPECIAL 01</template>
                        연신내역 역세권<br />
                        청년안심주택<br />
                        루미노816 특장점
                    </tit-wrap-primary>
                    <txt-primary class="white--text">지하철 및 GTX역을 갖춘 트리플 역세권의 <br class="d-none d-xl-block" />교통시설 및 생활 인프라를 가지고 있습니다.</txt-primary>
                    <div class="btn-wrap justify-start">
                        <v-btn tile outlined x-large width="160" href="/business/overview" color="white" class="btn-special btn-special--01">사업안내</v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="6" data-aos="fade-left" data-aos-delay="100">
                    <v-img eager src="/images/main/special-01.jpg" />
                </v-col>
            </v-row>
        </main-section-primary>

        <main-section-primary class="main-section--sm">
            <v-row align="center">
                <v-col cols="12" md="6" order-md="2">
                    <tit-wrap-primary titWrapSize="tit-wrap--sm" subTitSize="tit--xxs" subTitClass="secondary--text" titClass="font-weight-bold">
                        <template #TitAdd>SPECIAL 02</template>
                        편리한 인프라<br />
                        편의시설
                    </tit-wrap-primary>
                    <txt-primary
                        >다양하고 편리한 편의시설이 구현되어있으며 <br class="d-md-none d-lg-block" />
                        주변 편리한 인프라를 제공합니다.</txt-primary
                    >
                    <div class="btn-wrap justify-start">
                        <v-btn tile outlined x-large width="160" href="/complex/layout" class="btn-special btn-special--02">단지안내</v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="6" order-md="1" data-aos="fade-right" data-aos-delay="100">
                    <v-img eager max-width="692" src="/images/main/special-02.jpg" />
                </v-col>
            </v-row>
        </main-section-primary>

        <main-section-primary class="main-section--sm main-section--contact">
            <tit-wrap-primary titWrapSize="tit-wrap--sm" titWrapClass="text-center" subTitSize="tit--xxs" subTitClass="secondary--text" titSize="tit--lg" titClass="font-weight-regular white--text" data-aos="fade-up" data-aos-delay="100">
                <template #TitAdd>CONTACT US</template>
                당신의 처음과 함께하는<br />
                불광동 연신내역세권 청년안심주택<br />
                <span class="font-weight-bold">'루미노816'</span>
            </tit-wrap-primary>
            <div class="d-flex justify-center">
                <v-btn tile color="secondary" x-large width="160" href="/form/application">청약 신청</v-btn>
            </div>
        </main-section-primary>

        <!-- <main-section-secondary class="grey lighten-5">
            <template #tit> 섹션 타이틀 </template>

            <v-row align="center" justify="center">
                <v-col cols="auto">
                    <btn-primary to="/guide" class="v-btn--width-fixed">Guide</btn-primary>
                </v-col>
            </v-row>
            컨텐츠영역
        </main-section-secondary> -->

        <popup-layer />
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import MainSectionSecondary from "@/sets/parents/mains/main-section-secondary.vue";
import MainVisual from "@/sets/styles/mains/main-visual.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    components: {
        AppPrimary,
        PopupLayer,
        MainSectionPrimary,
        MainSectionSecondary,
        MainVisual,
        BtnPrimary,

        TitWrapPrimary,
        TitTertiary,
        TxtPrimary,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    @media (min-width: 1024px) {
        #main {
            padding-top: 0 !important;
        }
    }
}
.main-section {
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 1920px;
    margin: auto;
    &--special {
        background-image: url("/images/main/special-bg.jpg");
        background-position: left top;
    }
    &--contact {
        background-image: url("/images/main/contact-bg.jpg");
        background-position: center;
        aspect-ratio: 1920 / 960;
        max-width: 1920px;
        display: flex;
        align-items: center;
    }
}

.v-application .btn-special {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    &--01:hover {
        background-color: #fff !important;
        color: var(--v-grey-darken4) !important;
    }
    &--02:hover {
        background-color: var(--v-secondary-base) !important;
        border-color: var(--v-secondary-base) !important;
        color: #fff !important;
    }
}
@media (min-width: 768px) {
    .main-section--special {
        background-size: contain;
    }
}
</style>
