var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-section-primary', {
    staticClass: "main-section--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('tit-tertiary', {
    staticClass: "font-weight-bold"
  }, [_c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("불광동 루미노816"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 청년안심주택")]), _vm._v("은"), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 최고의 주거환경을 제공합니다.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('txt-primary', {
    staticClass: "line-height-15"
  }, [_vm._v(" 연신내역 역세권 청년안심주택 루미노816은 지하철 3호선·6호선 및 GTX역 인근에 위치하며 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 지하 3층 ~ 지상 22층, 총 299세대로 계획된 임대전용 공동주택입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 루미노816은 다양한 사업영역에서 축적한 노하우와 전문 인력들을 바탕으로 도시의 공간에 새로운 가치를 더하고, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 청년들의 첫 시작을 도우려고 노력하고 있습니다. ")])], 1)], 1)], 1), _c('main-section-primary', {
    staticClass: "main-section--sm main-section--special"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titWrapSize": "tit-wrap--sm",
      "subTitSize": "tit--xxs",
      "subTitClass": "secondary--text",
      "titClass": "white--text font-weight-bold"
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("SPECIAL 01")];
      },
      proxy: true
    }])
  }, [_vm._v(" 연신내역 역세권"), _c('br'), _vm._v(" 청년안심주택"), _c('br'), _vm._v(" 루미노816 특장점 ")]), _c('txt-primary', {
    staticClass: "white--text"
  }, [_vm._v("지하철 및 GTX역을 갖춘 트리플 역세권의 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v("교통시설 및 생활 인프라를 가지고 있습니다.")]), _c('div', {
    staticClass: "btn-wrap justify-start"
  }, [_c('v-btn', {
    staticClass: "btn-special btn-special--01",
    attrs: {
      "tile": "",
      "outlined": "",
      "x-large": "",
      "width": "160",
      "href": "/business/overview",
      "color": "white"
    }
  }, [_vm._v("사업안내")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "data-aos": "fade-left",
      "data-aos-delay": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "src": "/images/main/special-01.jpg"
    }
  })], 1)], 1)], 1), _c('main-section-primary', {
    staticClass: "main-section--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titWrapSize": "tit-wrap--sm",
      "subTitSize": "tit--xxs",
      "subTitClass": "secondary--text",
      "titClass": "font-weight-bold"
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("SPECIAL 02")];
      },
      proxy: true
    }])
  }, [_vm._v(" 편리한 인프라"), _c('br'), _vm._v(" 편의시설 ")]), _c('txt-primary', [_vm._v("다양하고 편리한 편의시설이 구현되어있으며 "), _c('br', {
    staticClass: "d-md-none d-lg-block"
  }), _vm._v(" 주변 편리한 인프라를 제공합니다.")]), _c('div', {
    staticClass: "btn-wrap justify-start"
  }, [_c('v-btn', {
    staticClass: "btn-special btn-special--02",
    attrs: {
      "tile": "",
      "outlined": "",
      "x-large": "",
      "width": "160",
      "href": "/complex/layout"
    }
  }, [_vm._v("단지안내")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "1",
      "data-aos": "fade-right",
      "data-aos-delay": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "692",
      "src": "/images/main/special-02.jpg"
    }
  })], 1)], 1)], 1), _c('main-section-primary', {
    staticClass: "main-section--sm main-section--contact"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titWrapSize": "tit-wrap--sm",
      "titWrapClass": "text-center",
      "subTitSize": "tit--xxs",
      "subTitClass": "secondary--text",
      "titSize": "tit--lg",
      "titClass": "font-weight-regular white--text",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("CONTACT US")];
      },
      proxy: true
    }])
  }, [_vm._v(" 당신의 처음과 함께하는"), _c('br'), _vm._v(" 불광동 연신내역세권 청년안심주택"), _c('br'), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("'루미노816'")])]), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "color": "secondary",
      "x-large": "",
      "width": "160",
      "href": "/form/application"
    }
  }, [_vm._v("청약 신청")])], 1)], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }